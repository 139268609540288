.progressive {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none;
}
.progressive img {
  display: block;
  width: 100%;
  max-width: none;
  height: auto;
  border: 0;
}
.progressive img.preview {
  filter: blur(0vw);  // custtomize imgaes's blur effect, keep it zero for now.
  transform: scale(1);
}
.progressive img.reveal {
  position: absolute;
  left: 0;
  top: 0;
  animation: progressiveReveal 1s linear;
}
@keyframes progressiveReveal {
  0% {
    transform: scale(1.05);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 120%;
  line-height: 1.4;
  color: #444;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
}

article {
  max-width: 152em; // customized page's width
  padding: 0 0.5em;
  margin: 0 0.5em;
}

.gallery {
  margin: 0.5em auto;
  display: flex;
  /* flex-wrap: wrap; */
  /* align-content: flex-start; */
  justify-content: space-between;
}
.jumbotron {
  background: url("background-weatherSensitivity.jpg") center center / cover
    no-repeat;
}
// customized bootstrap4's container's width
@media (min-width: 1200px) {
  .container {
    max-width: 152em; // set this to page width.
  }
}



/* -------------------------------------- */    
/* rounded  list style start              */ 		

/* list */
.rounded-list ol 
{
counter-reset:li; /* Initiate a counter */
margin-left:2.6em; /* Remove the default left margin */
padding-left:2.6em; /* Remove the default left padding */
}

/* item  */
.rounded-list ol > li
{
position:relative; /* Create a positioning context */
list-style:none; /* Disable the normal item numbering */
background:#f6f6f6; /* Item background color */
margin:10; /* Give each list item a left margin to make room for the numbers */
padding-left: 2.6em; /* Add some spacing around the content */
padding-bottom:0px;
padding-top:0px;
}

/* number  */
.rounded-list ol > li p:before
{
content: counter(li); 
counter-increment: li; 
position: absolute; 
left: 0.3em;
top: 50%;

/* number background */
background: #87ceeb; 
height: 2em;
width: 2em;
margin-top: -1em;
line-height: 1.5em;
border: .3em solid #fff;
text-align: center;
font-weight: bold;
border-radius: 2em;
}

/* -------------------------------------- */    
/* rounded  list style end               */ 	

nav[data-toggle="toc"] {
  top: 42px;
  
}


/* small screens */
@media (max-width: 768px) {
  /* override stickyness so that the navigation does not follow scrolling */
  nav[data-toggle="toc"] {
    margin-bottom: 42px;
    position: static;
  }

  /* PICK ONE */
  /* don't expand nested items, which pushes down the rest of the page when navigating */
  nav[data-toggle="toc"] .nav .active .nav {
    display: none;
  }
  /* alternatively, if you *do* want the second-level navigation to be shown (as seen on this page on mobile), use this */
  /*
  nav[data-toggle='toc'] .nav .nav {
    display: block;
  }
  */
}

// #myDiv{
//   display: flex;
//   flex-wrap: wrap;
// }


iframe {
  margin-top: 20px;
  margin-bottom: 30px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  -moz-box-shadow: 4px 4px 14px #000;
  -webkit-box-shadow: 4px 4px 14px #000;
  box-shadow: 4px 4px 14px #000;
  -moz-transform:rotate(0deg);
  -webkit-transform:rotate(0deg);//change to 0
  -o-transform:rotate(0deg);
  -ms-transform:rotate(0deg);
  filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=.2);
  overflow: hidden; // disable scroll bar
  } 